<script setup>
const {t} = useI18n()
const app = useNuxtApp()
const route = useRoute()
const settingsStore = useSettingsStore()
const settings = await settingsStore.getSettings()
useHead({
  title: computed(
    () => `${route.meta.title ? t(`${route.meta.title}`) + ' | ' : ''}${settings?.white_label_display_name}`,
  ),
})
const themeName = ref(app.$themeData && app.$themeData.name)
</script>
<style lang="scss" scoped>
.layout-default {
  > .container-xl {
    min-height: 65vh;
  }
}
::v-deep(.container-xl) {
  @include for-non-mobile-only {
    max-width: 1254px;
  }
}
</style>
<template>
  <div
    :id="'nu-theme-' + themeName"
    class="layout-default"
  >
    <NuHeader />
    <div
      :id="'page-' + route?.name?.split('__')[0]"
      class="container-xl"
    >
      <slot />
    </div>
    <NuFooter />
  </div>
</template>
